"use strict";

import axios from "axios";
var baseControllerAddress = 'gateSchedule/';
// GateSchedule
// gateSchedule
export default {
    getGateScheduleList: (vm) => {
        return axios.post('/api/' + baseControllerAddress + 'filterGateScheduleList', vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                    if (error.response.status != 401) {
                            alert(error);
                    }
            });
    },
    updateGateSchedule: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    createGateSchedule: (vm) => {
        // debugger;
        // vm.fromTime += ":00";
        // vm.toTime += ":00";
        return axios.post('/api/' + baseControllerAddress + "create/", vm)
            .catch(function (error) {
                alert(error);
            });
    },
    getGateSchedule: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteAll: () => {
        return axios.delete('/api/' + baseControllerAddress + "deleteAllGateSchedules")
            .catch(function (error) {
                alert(error);
            });
    },
    deleteGateSchedule: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "delete/" + id)
            .catch(function (error) {
                alert(error);
            });
    },
    inSchedule: (gateId) => {
        return axios.get('/api/' + baseControllerAddress + 'inSchedule/' + gateId)
    }
   
    
}